<template>
  <div class="page">
<!--    <el-popover-->
<!--        placement="bottom-end"-->
<!--        width="600"-->
<!--        :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"-->
<!--        v-model="visible">-->
<!--      <el-form label-width="70px" label-position="left">-->
<!--        <el-form-item label="搜索方式">-->
<!--          <el-input size="small" v-model="searchObj.keyWord">-->
<!--            <el-select style="width: 100px" v-model="searchObj.searchWay" slot="prepend" placeholder="请选择">-->
<!--            </el-select>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="申请时间">-->
<!--          <el-date-picker size="small"-->
<!--                          v-model="searchObj.time"-->
<!--                          type="daterange"-->
<!--                          align="right"-->
<!--                          unlink-panels-->
<!--                          range-separator="至"-->
<!--                          start-placeholder="开始日期"-->
<!--                          end-placeholder="结束日期"-->
<!--                          :picker-options="pickerOptions">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->
<!--        <div style="display: flex;flex-direction: row">-->
<!--          <el-form-item label="订单类型">-->
<!--            <el-select size="small" v-model="searchObj.orderType" placeholder="请选择">-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="订单状态">-->
<!--            <el-select size="small" v-model="searchObj.orderState" placeholder="请选择">-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="营销类型">-->
<!--            <el-select size="small" v-model="searchObj.marketType" placeholder="请选择">-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--        <div style="display: flex;flex-direction: row">-->
<!--          <el-form-item label="付款方式">-->
<!--            <el-select size="small" v-model="searchObj.payWay" placeholder="请选择">-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="订单来源">-->
<!--            <el-select size="small" v-model="searchObj.orderSource" placeholder="请选择">-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="结算状态">-->
<!--            <el-select size="small" v-model="searchObj.payState" placeholder="请选择">-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </div>-->
<!--        <div style="display: flex;flex-direction: row;justify-content: center">-->
<!--          <el-button size="mini" type="primary" @click="search">搜索</el-button>-->
<!--          <el-button size="mini" @click="initSearch">重置</el-button>-->
<!--          <el-button size="mini">导出订单商品</el-button>-->
<!--          <el-button size="mini">导出订单</el-button>-->
<!--        </div>-->
<!--      </el-form>-->
<!--      <el-button slot="reference">搜索</el-button>-->
<!--    </el-popover>-->
    <div class="fucView">
<!--      <span style="margin-right: 10px;">批量操作</span>-->
<!--      <el-button type="text" @click="showShipping = true">批量发货</el-button>-->
<!--      <el-button type="text" @click="">批量打印发货单</el-button>-->
<!--      <el-button type="text" @click="">批量导出发货单（菜鸟专用）</el-button>-->
<!--      <el-button type="text" @click="exportShippingOrder">导出待发货单</el-button>-->
      <el-link :href="linkUrl" type="primary" target="_blank" :underline="false">导出待发货单</el-link>
<!--      <el-button type="text" @click="showPrintTicket = true">打印电子面单</el-button>-->
      <el-button type="text" @click="showUpLoadDeliveryList = true" style="margin-left: 10px">导入发货单</el-button>
    </div>
<!--    <listTable state="all" :page="currentPage" :canChoosed="true" @chooseHandle="chooseHandle"/>-->
    <el-table :data="orderList">
      <el-table-column :show-overflow-tooltip="true" label="商品订单号" prop="order_sn"/>
      <el-table-column :show-overflow-tooltip="true" label="商品订单状态" prop="order_state_text"/>
      <el-table-column :show-overflow-tooltip="true" label="商品物品" prop="goods_name"/>
      <el-table-column :show-overflow-tooltip="true" label="收件人信息" prop="address"/>
      <el-table-column :show-overflow-tooltip="true" label="用户指定物流" prop="logi_buyer_chosen"/>
      <el-table-column label="物流信息">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            <el-select size="mini" v-model="scope.row.logi_company" clearable>
              <el-option v-for="item in logiList" :value="item.id" :label="item.name"/>
            </el-select>
            <el-input style="margin-left: 5px" size="mini" v-model="scope.row.logi_number" clearable/>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80px">
        <template slot-scope="scope">
          <el-button size="mini" @click="save(scope.row,scope.$index)">保存</el-button>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="物流状态" prop="logi_status_text"/>
    </el-table>
    <div style="display: flex;justify-content: flex-end;margin-top: 10px;">
      <el-pagination layout="prev, pager, next" :total="pageCount" :current-page.sync="search.page" hide-on-single-page="false" @current-change="pageChangeHandle"/>
    </div>

    <shipping :visible.sync="showShipping" title="批量发货" id="[1,2]"></shipping>
    <printTicket :visible.sync="showPrintTicket" title="打印电子面单" id="[1,2]"></printTicket>
    <upLoadDeliveryList :visible.sync="showUpLoadDeliveryList" title="上传发货单"></upLoadDeliveryList>
  </div>
</template>

<script>
import listTable from '@/views/order/components/listTable.vue'
import shipping from "@/views/shipping/components/shipping";
import printTicket from "@/views/shipping/components/printTicket";
import upLoadDeliveryList from "@/views/shipping/components/upLoadDeliveryList";
import {apiExportShippingOrder, apiGetWaitingShip, apiOrderShip} from "@/request/api";
export default {
  name: "shippingList",
  components: {
    listTable,
    shipping,
    printTicket,
    upLoadDeliveryList,
  },
  data() {
    return {
      showShipping:false,
      showPrintTicket:false,
      showUpLoadDeliveryList:false,
      pageCount: 0,
      search: {},
      orderList:[],
      logiList:[],
      linkUrl:''
    }
  },
  created() {
    this.initSearch()
    this.getWaitingShip()
    this.linkUrl = 'https://api.odecmall.com/order/order/export-waiting-for-ship-list?role=admin&token='+JSON.parse(localStorage.getItem('token'))
  },
  methods: {
    exportShippingOrder(){
      apiExportShippingOrder().then(res => {
        console.log(res)
      })
    },
    pageChangeHandle(){
      this.getWaitingShip()
      window.screenTop = 0;
    },
    initSearch(){
      this.search = {
        page: 1,
        'per-page': 10,
      }
    },
    getWaitingShip(){
      apiGetWaitingShip(this.search).then(res => {
        if(res.code == 200) {
          this.pageCount = res.data.count
          this.orderList = res.data.list.order_list
          this.logiList = res.data.list.logi_list
        }else {
          this.$message.error(res.message)
        }
      })
    },
    save(row,index){
      apiOrderShip({order_sn:row.order_sn,logi_id:row.logi_company,number:row.logi_number}).then(res =>{
        if(res.code == 200){
          this.$message.success(res.message)
          this.orderList[index].logi_status_text = res.data.logi_status_text
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleClick(tab, event) {
      this.orderState = tab.name
    },
    chooseHandle(){

    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.searchView {
  display: flex;
  align-items: center;
}
</style>
