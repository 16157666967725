<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-form label-width="110px" label-position="left">
<!--      <el-form-item label="导入表格格式">-->
<!--        <el-radio-group v-model="format">-->
<!--          <el-radio-button :label="1">菜鸟</el-radio-button>-->
<!--          <el-radio-button :label="2">通用</el-radio-button>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
<!--      <span style="display: block;margin-left: 110px;margin-top: 10px">tips:</span>-->
<!--      <span style="display: block;margin-left: 110px;margin-top: 10px">1.先导出菜鸟专用格式，在菜鸟发货后，修改表格，增加2列单号后，将可在这导入</span>-->
<!--      <span style="display: block;margin-left: 110px;margin-top: 10px">2.导出通用的发货单后，完善物流公司和单号后可导入</span>-->
      <el-form-item label="选择文件">
        <el-upload
            class="upload-demo"
            action="https://api.odecmall.com/order/order/import-number-file"
            :on-success="uploadSuccessHandle"
            :multiple="false"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :data="{role:'admin'}"
            :show-file-list="false"
            drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </el-form-item>
    </el-form>
    <el-table v-if="tableData.length > 0" :data="tableData">
      <el-table-column v-for="(item,index) in tableHeaderOrigin" width="200px">
        <template slot="header" slot-scope="scope">
          <el-select v-model="tableHeaderNewType[index]" clearable>
            <el-option v-for="mapitem in tableFiledMap" :value="mapitem" :disabled="mapitem | AbleFilter(tableHeaderNewType)">{{mapitem}}</el-option>
          </el-select>
        </template>
        <template slot-scope="scope">
          {{scope.row[item]}}
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">

      <el-button @click="close">
        关闭
      </el-button>
      <el-button type="primary" :disabled="!file || tableData.length == 0" @click="handelConfirm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiSaveShippingOrder} from "@/request/api";

export default {
  inheritAttrs: false,
  name: "upLoadDeliveryList",
  filters:{
    AbleFilter(value,list){
      return list.some(item => {return item == value})
    }
  },
  data(){
    return{
      innerVisible:false,
      tableData:[],
      tableHeaderOrigin:[],
      tableHeaderNewType:[],
      tableFiledMap:{},
      file:'',
    }
  },
  methods:{
    onOpen(){

      this.tableData=[]
          this.tableHeaderOrigin=[]
          this.tableHeaderNewType=[]
          this.tableFiledMap={}
      this.file=''
    },
    onClose() {
    },
    closeInner(){
      this.innerVisible = false;
      this.close()
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      var header = []
      this.tableHeaderOrigin.forEach((item,index) => {
        header.push({
          origin:item,
          new_type:this.tableHeaderNewType[index]
        })
      })
      apiSaveShippingOrder({file:this.file,header}).then(res => {
        console.log(res)
        if(res.code == 200) {
          this.uploadData = this.uploadData + res.data
        }else{
          this.$message.error(res.message)
        }
      })
      // this.innerVisible = true
    },
    uploadSuccessHandle(res){
      this.tableFiledMap = res.data.filedMap
      this.tableHeaderOrigin = res.data['header_index'].map(item => {return item.origin})
      this.tableHeaderNewType = res.data['header_index'].map(item => {return item['new_type'][0] == '-'?'':item['new_type']})
      this.tableData = res.data.data
      this.file = res.data.file
    }
  }
}
</script>

<style scoped>

</style>
