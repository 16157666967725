import { render, staticRenderFns } from "./shippingList.vue?vue&type=template&id=2e7f4568&scoped=true&"
import script from "./shippingList.vue?vue&type=script&lang=js&"
export * from "./shippingList.vue?vue&type=script&lang=js&"
import style0 from "./shippingList.vue?vue&type=style&index=0&id=2e7f4568&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e7f4568",
  null
  
)

export default component.exports